import { css } from "styled-components"

export const businessMoto = css`

  section { 
    background-color: #F9F9F9;
    padding-bottom: 100px;
  }

  .logo-container {

    margin: 0 auto;
    margin-bottom: 20px;
    background-color: #48E4B7;
    border-radius: 0px 0px 10px 10px;
    width: 100px;
    height: 131px;
    position: relative;

    .logo {
      position: absolute;
      left: calc(50% + 5px);
      top: calc(50% + 5px);
      transform: translateX(-50%) translateY(-50%);

      /* @temp */
      width: 50px;
      height: 60px;
      /* background-color: black; */
    }

  }

  .content {

    .text {
      text-decoration: underline;
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 40px;
    }

    .heading {
      font-size: 32px;
      font-style: italic;
    }

  }

`