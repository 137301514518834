import { css } from "styled-components"

export const storyBehind = css`

  position: relative;

  section {
    background-color: #F9F9F9;
    padding-bottom: 100px;

    background-image: url("/logo_background_transparent.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 60vw;
    background-position-y: -16vw;
    padding-top: 0;

    @media (max-width: 768px) {
      background-position-x: -95vw;
    }

  }

  .section-title {
    padding-top: 110px;
    box-sizing: border-box;
  }

  .text {
    font-size: 24px;
    line-height: 34px;
  }

  .section-text-separator {
    height: 60px;
    display: flex;
    width: 100%;
    margin-top: 35px;
    margin-bottom: 51px;

    &__left-line,
    &__right-line {
      margin-top: 29px;
      background-color: black;
      height: 1px;
      width: 100%;
    }

    &__logo {
      flex-shrink: 0;
      margin: 0 60px;

      /* @temp */
      width: 50px;
      height: 60px;
      /* background-color: black; */

      @media (max-width: 768px) {
        margin: 0 40px;
      }
    }
  }

`