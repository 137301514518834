import { css } from "styled-components"

export const theRules = css`

  section { 
    background-color: #48E4B7;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .rules-list {

    .rule {

      max-width: 1100px;

      &:not(:last-child) {
        margin-bottom: 30px;

        @media (max-width: 768px) {
          margin-bottom: 50px;
        }
      }
      
      &__heading {
        font-size: 32px;
        line-height: 52px;

        @media (max-width: 768px) {
          line-height: 42px;
        }
      }

      &__content {

        margin-top: 20px;
        display: flex;

        &__left-rhombus {
          flex-shrink: 0;
          margin-top: 7px;

          background-color: #FCEDBD;
          transform: rotate(45deg);
          width: 21px;
          height: 21px;
          border-radius: 3px;
        }

        &__text {
          margin-left: 20px;

          font-size: 24px;
          line-height: 34px;
        }

      }

    }

  }

`