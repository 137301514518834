import React from "react"

import { MainLayout } from "../../layouts/main.layout"
import StandardPageHeader from "../../components/layout-components/top/headers/standard-page-header/StandardPageHeader.component"
import PageSection from "../../layouts/PageSection/PageSection.component"
import CenterContainer from"../../layouts/containers/CenterContainer/CenterContainer.component"
import MainCtaButton from "../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import Footer from "../../components/layout-components/bottom/footer/Footer.component"
import { getCanonicalUrl } from "../../utils/getCanonicalUrl"

import { howItAllBegan } from "./css/howItAllBegan.css"
import { storyBehind } from "./css/storyBehind.css"
import { theTeam } from "./css/theTeam.css"
import { theRules } from "./css/theRules.css"
import { businessMoto } from "./css/businessMoto.css"

import HireUs from "../../components/layout-components/_/other/HireUs/HireUs.component"
import { getStaticImgUrl } from "../../utils/getStaticImgUrl"


const meta = {
  title: "Our Story",
  description: "We started out in 2011 as a ragtag team of English lit majors, gamers, musicians, and free-spirited bloggers working for a local digital marketing company."
}

const og = {
  image: getStaticImgUrl("unsplash-photo-1461988625982-7e46a099bf4f-d.jpeg")
}

const pageSlug = "our-story";

export default () => (

  <MainLayout meta={meta} og={og} canonicalUrl={getCanonicalUrl(pageSlug)}>
    


    {/* 
    
          Header

    */}

    <StandardPageHeader 
      data={
        {
          heading: "Our Podroom Creative Writing Brand",
          backgroundImage: "/unsplash-photo-1461988625982-7e46a099bf4f-d.jpeg"
        }
      } 
    />


    
    {/* 
    
          "How it al began"

    */}

    <PageSection extendStyles={howItAllBegan}>
      
      <h2 className="section-title">How it all began?</h2>
      
      <div className="texts">

        <p className="texts--left">We started out in 2011 as a ragtag team of English lit majors, gamers, musicians, and free-spirited bloggers working for a local digital marketing company.</p>

        <p className="texts--right">As you can imagine, it was a fun but hectic few years before we got our act together, formed our core team and built a professional company of our own.</p>

      </div>

      <div className="background-boxes background-boxes--2">
          <div className="box"></div>
      </div>
      <div className="background-boxes background-boxes--3">
        <div className="box"></div>
      </div>
      <div className="background-boxes background-boxes--4">
        <div className="box"></div>
      </div>

    </PageSection>



    {/*
    
          "Story behind"

    */}

    <PageSection extendStyles={storyBehind} fullWidth={true}>

      <CenterContainer>

        <h2 className="section-title text-center">The story behind our name</h2>

        <p className="text text-center bold">After bouncing around town for a couple of years, switching between three locations for our office, each one a bit nicer than the old one, we eventually found the perfect space for our HQ – a big old basement smack bang at the center of town. <br /><br />It was great – a lot of space, a separate room for breaks and casual conversation, cool in the scorching hot summers, easy to heat up in the winter, even a small gym right across the hall. Well, we liked the place so much that we are still here, although we’ve fixed it up quite a bit.</p>

        <div className="section-text-separator">
          <div className="section-text-separator__left-line"></div>
          <div className="section-text-separator__logo">
            <img src="/ico_logo.png" />
          </div>
          <div className="section-text-separator__right-line"></div>
        </div>

        <p className="text">When it came time for our core team to go its separate way and rebrand, we thought “Hell, this basement is such a big part of our identity and our lives that it’s basically like another member of the team.”<br /><br /> It was within these walls that unique ideas were being bounced around for a couple of years, our little team growing into something bigger and much more substantial, our new HQ acting like a protective pod sheltering these tiny creative seeds. Our roots were planted firmly underground, and from there, we could spread our digital stems all over the world.<br /><br /> The basement was essentially the room where we planted our first hopeful creative pod, and as luck would have it, the Serbian word for “basement” is “podrum” (pronounced pod-room), which made for a great little pun. Being the nerds that we are, it sounded catchy and fun to us, so we stuck with Podroom Creative.</p>

      </CenterContainer>

    </PageSection>
    


    {/* 
    
          "The team"

    */}

    <PageSection extendStyles={theTeam}>

      <div className="the-team the-team--first-part">

        <div className="look-at-us-now">

          <h2 className="section-title">Look at us now</h2>

          <p className="text">Today, some seven years after first coming together, that renegade spirit and creativity that made us chase our fortune online is still there, only we have put it to good use.</p>

        </div>

        <div className="the-team">
  
          <h2>The Team</h2>

          <div className="texts">
            <p className="texts__left text">We have people from all walks of life on our team, from fitness freaks to movie buffs, from gamers to new moms and dads, from tech junkies to hikers and DIY enthusiasts. All that diversity lets us tackle our writing assignments from many interesting angles and offer writing services for a variety of specialized niches. We try to keep our little group happy so that our writers can stay motivated through their hours of research,</p>
            <p className="texts__right text">and dozens of dad jokes that our senior editor subjects them to during coffee breaks. Most of the time it works out quite well, even when the jokes land flat. <br /><br />Scroll down to read a bit about all our fantastic team members who work hard every day to get our clients’ projects done in record time</p>
          </div>

        </div>

      </div>

      <div className="the-team the-team--second-part">

        <div className="team-roster">

          {/*   Pavle Dinić  */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--paxon"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Up at the break of dawn, with the roosters and the city’s diligent bakers, who see him as their best customer – our CEO is an avid console gamer, burek connoisseur, all-around family man, and a stickler for details. When it comes to quality control, nothing gets past this guy, trust us.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link up" href="https://www.upwork.com/freelancers/~01dede564486fa1063" target="_blank"></a>
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/paxonpaxon/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Pavle Dinić</p>
              <p className="team-roster__card__person-title">CEO of Podroom Creative Writing</p>
            </div>

          </div>

          {/*   Jovan Ilić   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--joca"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Our chief client manager is a man of simple tastes – he likes his deals transparent and well-defined, and his coffee thick, black, and strong enough to kill a bear. He’s a great guy, after his morning coffee ritual, and one hell of a negotiator. Whatever you need, just give him a shout – he’ll hook you up.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link up" href="https://www.upwork.com/freelancers/~0156f024fb74ebbbfa" target="_blank"></a>
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/jovan-ilic-36976857/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Jovan Ilić</p>
              <p className="team-roster__card__person-title">CEO of Podroom Creative Marketing</p>
            </div>

          </div>

          {/*   Nemanja Manojlović   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--zuki"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Our senior editor is a unique blend of bad puns, literary references, movie quotes, and fitness wisdom. It’s a bit of an odd package, but he’s good at his job, punctual, and always ready to help, so the rest of the team lets his quirky humor slide – for now.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/nemanja-manojlovic-0b44b063/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Nemanja Manojlović</p>
              <p className="team-roster__card__person-title">Editor-in-Chief</p>
            </div>

          </div>

          {/*   Julija Sabo   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--jeca"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Ever the bookworm, Jelena always seems to find a way to bring new life to a topic she’s writing about, be it home improvement, travel, business, or IT related. She’s also a new mom, so she understands the finesse involved in working under pressure and tackling lots of important tasks in a short time.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/jelena-dini%C4%87-3aa078128/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Jelena Dinić</p>
              <p className="team-roster__card__person-title">Senior Content Writer</p>
            </div>

          </div>


          {/*   Vladimir Ilić   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--brile"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Smarter and much more dependable than your average metalhead bassist, Vladimir is a rockstar when it comes to fitness, outdoors, tech, and business content. Fueled by the same tough and resourceful hillside spirit as his brother Aleksandar, he can tackle any task set before him and just keep asking for more.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/vladimirilicbrile/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Vladimir Ilić</p>
              <p className="team-roster__card__person-title">Senior Content Writer</p>
            </div>

          </div>


          {/*   Nenad Cvetanović   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--nenad"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">Nenad Cvetanovis is a creative writer and blogger at Podroom Creative. He has a Master’s degree in Psychology from the Faculty of Philosophy, University of Nis, and is currently involved in the Gestalt Therapy Training Program. Apart from being an avid writer of articles concerning psychology, he enjoys writing about IT and HR related topics as well.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/nenadcvetanovic/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Nenad Cvetanović</p>
              <p className="team-roster__card__person-title">Senior Content Writer</p>
            </div>

          </div>


          {/*   Miljana Poznanović   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--miljana"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">An English lit major with a penchant for baking, the martial arts, horror movies and fantasy novels. When Miljana’s not karate-chopping bread dough or walking her dog, you can find her scrunched up at her laptop in some cozy corner, exploring the worlds of business and finance, technology, and health.</p>
                <div className="team-roster__card__content__links">
                  <a className="team-roster__card__content__links__link ln" href="https://www.linkedin.com/in/miljana-poznanovic-80606446/" target="_blank"></a>
                </div>
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">Miljana Poznanović</p>
              <p className="team-roster__card__person-title">Senior Content Writer</p>
            </div>

          </div>


          {/*   Y O U   */}

          <div className="team-roster__card">

            <div className="team-roster__card--container">
              <div className="team-roster__card__photo pc--you"></div>
              <div className="team-roster__card__content">
                <div className="team-roster__card__content__logo">
                  <img src="/ico_logo.png" />
                </div>
                <p className="team-roster__card__content__text">We would be more than happy to hear your story, or maybe you want to write it down ;) ?<br /><br />If yes, try clicking a button bellow.</p>
                <MainCtaButton buttonText="Contact Us" />
              </div>
            </div>

            <div className="team-roster__card--bottom">
              <p className="team-roster__card__person-name">This could be you?</p>
              <p className="team-roster__card__person-title">Yes, we are hiring!</p>
            </div>

          </div>

        </div>

      </div>
      
    </PageSection>



    {/* 
    
          "The rules we live by"

    */}

    <PageSection extendStyles={theRules} fullWidth={true}>

      <CenterContainer>

        <h2 className="section-title">The rules we live by</h2>

        <div className="rules-list">

          <div className="rule">
            <h4 className="rule__heading">Always Balance Work and Play</h4>
            <div className="rule__content">
              <div className="rule__content__left-rhombus"></div>
              <p className="rule__content__text">We don’t want anyone to burn out, but we most certainly don’t want to keep clients waiting because someone fell into a food coma and took a 2-hour power nap. Having a good balance is the key to productivity.</p>
            </div>
          </div>

          <div className="rule">
            <h4 className="rule__heading">Research, Understand, Convey</h4>
            <div className="rule__content">
              <div className="rule__content__left-rhombus"></div>
              <p className="rule__content__text">To get a handle on a new topic, our writers do copious amounts of research to understand the industry and your target audience’s main pain points. They can then convey your brand message loud and clear.</p>
            </div>
          </div>

          <div className="rule">
            <h4 className="rule__heading">Keep Things Honest and Transparent</h4>
            <div className="rule__content">
              <div className="rule__content__left-rhombus"></div>
              <p className="rule__content__text">When negotiating with clients, we like to shoot straight and be as open as possible about our methods, requirements, and pricing. We make sure that everyone is on the same page before starting a project.</p>
            </div>
          </div>

          <div className="rule">
            <h4 className="rule__heading">Establish Long-term Relationships</h4>
            <div className="rule__content">
              <div className="rule__content__left-rhombus"></div>
              <p className="rule__content__text">Our clients mean more to us than just a quick payment. Satisfied clients help spread the word about us, and if they stick around with us, the communication and workflow only get more efficient over time</p>
            </div>
          </div>

          <div className="rule">
            <h4 className="rule__heading">Don’t Bite Off More than You Can Chew</h4>
            <div className="rule__content">
              <div className="rule__content__left-rhombus"></div>
              <p className="rule__content__text">We’ve seen a lot in this business, but we don’t claim to know it all. If a project is outside our area of expertise or the deadline is too tight, we’ll tell you right away. There’s no reason to sacrifice quality or rush things.</p>
            </div>
          </div>

        </div>

      </CenterContainer>

    </PageSection>



    {/* 
    
          "Our business moto

    */}

    <PageSection extendStyles={businessMoto} fullWidth={true}>

      <CenterContainer>

        <div className="logo-container">
          <div className="logo">
            <img src="/ico_logo.png" />
          </div>
        </div>

        <div className="content text-center">
          
          <p className="text">Our business motto</p>

          <h5 className="heading">Take pride in your work – be meticulous, inventive, and adaptable.</h5>

        </div>

      </CenterContainer>

    </PageSection>



    {/* 
    
          "Hire us"

    */}

    <HireUs />



    {/* 

          "Footer"

    */}

    <Footer />

    
  </MainLayout>

)