import { css } from "styled-components"

export const howItAllBegan = css`

  section {
    padding-top: 140px;
    padding-bottom: 150px;
  }

  .texts {

    display: flex;
    position: relative;
    z-index: 2;

    p { 
      font-size: 24px;
      line-height: 34px;
    }

    &--left {
      margin-right: 30px;
      max-width: 552px;
    }
    
    &--right {
      max-width: 543px;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      p {
        max-width: 550px;
        margin-bottom: 40px;
      }
      
    }

  }

  .background-boxes {

    height: 155px;
    display: flex;
    z-index: 1;

    .box {
      background-color: #F9F9F9;
      display: inline-block;
      height: 100%;
    }

    &--1,
    &--2,
    &--3 {
      width: 60%;
    }

    &--2 {
      
      z-index: 1;
      margin-top: -100px;

      .box {
        /* margin-left: 515px; */
        margin-left: 76.5%;
        width: 170px;
        border-radius: 40px 0px 0px 0px;
      }

      @media (max-width: 768px) {
        position: relative;
        width: 100%;

        .box {
          width: 50vw;
          position: absolute;
          margin-left: initial;
          right: 0;
          border-radius: 40px 0 40px 0;
        }
      }
      
    }
    &--3 {
      .box {
        width: 236px;
        border-radius: 40px 0px 0px 0px;
      }
      float: left;
    }
    &--4 {
      float: left;
      .box {
        width: 381px;
      }
    }

  }

`